<template>
  <button class="btn botao-modal-ok" v-on:click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BotaoModalOk",
};
</script>

<style scoped>

button.botao-modal-ok {
    background-color:#1DC53F;
    color: #fff;
    border-radius: 0px;
}
.botao-modal-ok {
  background-color: #19e18c;
  color: #fff;
  border-radius: 0px;
}

.botao-modal-ok:hover {
  background-color: #19e18ed0;
  color: rgba(255, 255, 255, 0.822);
  border-color: rgba(47, 194, 47, 0.76);
}

.botao-modal-ok:disabled {
  background-color: var(--cinza-4);
  color: #fff;
}
</style>