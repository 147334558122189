<template>
    <div>
        <label class="w-100 m-0">
            {{label}}

            <money class="input-money"
                   v-bind="moneyMask"
                   :value="value"
                   @input="$emit('input', $event)">
            </money>
        </label>
    </div>
</template>

<script>
    import { Money } from 'v-money'

    export default {
        components: { Money },
        props: {
            label: { String, default: 'Valor' },
            value: { Number, default: 0 },
            prefixo: Boolean
        },
        computed: {
            moneyMask() {
                return {
                    prefix: this.prefixo ? 'R$ ' : '',
                    decimal: ',',
                    thousands: '.',
                    precision: 2,
                    masked: false
                }
            }
        },
    }
</script>

<style scoped>
    .input-money {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }
</style>